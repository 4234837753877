<template>
  <div>
    <c-tab
      type="horizon"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :dense="true"
      :height="tabHeight"
      :tabOptions.sync="tabOptions"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :param="popupParam"
          :psmFlag.sync="psmFlag"
          @insertEquipInfo="updateMode"
        />
      </template>
    </c-tab>
  </div>
</template>
1
<script>
export default {
  name: 'chem-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        tabName: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'chemInfo',
      tabItems: [
        { name: 'chemInfo', icon: 'info', label: '화학자재 기본정보', class:'tab-orange', component: () => import(`${'./chemInfo.vue'}`) },
      ],
      addTabItems: [
        { name: 'chemEtc', icon: 'settings_applications', label: '화학자재 상세정보', component: () => import(`${'./chemEtc.vue'}`) },
        { name: 'chemMsds', icon: 'dangerous', label: '구성물질 정보', component: () => import(`${'./chemComponents.vue'}`) },
        { name: 'chemPsm', icon: 'warning_amber', label: '유해화학물질(PSM) 정보', component: () => import(`${'./chemPsm.vue'}`) },
      ],
      psmFlag: {
        bool: false,
        watch: '',
      },
      tabOptions: {
        chemPsm: {
          tabLabelStyle: ''
        },
      }
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
  },
  watch: {
    'psmFlag.watch'() {
      this.$set(this.tabOptions['chemPsm'], 'tabLabelStyle', !this.psmFlag.bool ? 'text-decoration-line:line-through;text-decoration-color:#C10015' : '')
      this.$set(this.tabItems[this.$_.findIndex(this.tabItems, { name: 'chemPsm' })], 'disabled', !this.psmFlag.bool ? true : false)
    },
  },
  methods: {
    init() {
      if (this.popupParam.tabName) {
        this.tab = this.popupParam.tabName;
      }
      if (this.popupParam.mdmChemMaterialId != '') {
        this.tabItems = this.tabItems.concat(this.addTabItems);
      }
    },
    updateMode(_mdmChemMaterialId) {
      this.popupParam.mdmChemMaterialId = _mdmChemMaterialId;
      this.tabItems = this.tabItems.concat(this.addTabItems);
    },
  }
};
</script>